import { docsLink, slackLink, statusLink, githubLink } from "constants/Links";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { ROUTES } from "routes/constants";
import { faCrown, faBookOpen, faUserGroup } from "@fortawesome/pro-regular-svg-icons";
import { faCode } from "@fortawesome/pro-duotone-svg-icons";

export interface MenuItemType {
  name: string;
  link: string;
  description?: string;
  icon: IconProp; // Using direct imports from @fortawesome/pro-regular-svg-icons
}

export interface NavItemType {
  name: string;
  link?: string;
  isMenu?: boolean;
  menuItems?: MenuItemType[];
  featuredArticle?: {
    slug?: string;
  };
}

// Define a new "Resources" submenu items
export const resourcesMenuItems: MenuItemType[] = [
  {
    name: "Customers",
    link: ROUTES.customers,
    description: "Learn how teams build and scale their AI apps with Beam.",
    icon: faCrown
  },
  {
    name: "Blog",
    link: ROUTES.blog,
    description: "Stay ahead with technical tutorials and product updates.",
    icon: faBookOpen
  },
  {
    name: "Join Slack Community",
    link: slackLink,
    description: "Ask questions, get help, and connect with other developers in our community.",
    icon: faUserGroup
  },
  {
    name: "About",
    link: ROUTES.about,
    description: "We’re more than a cloud provider—learn about our mission.",
    icon: faCode
  }
];

const NavItems: NavItemType[] = [
  {
    name: "Resources",
    isMenu: true,
    menuItems: resourcesMenuItems,
    featuredArticle: {
      slug: "geospy-case-study"
    }
  },
  {
    name: "Pricing",
    link: ROUTES.pricing
  },
  {
    name: "Status",
    link: statusLink
  },
  {
    name: "Documentation",
    link: docsLink
  }
];

export default NavItems;
