export const ROUTES = {
  home: "/",
  beam: "/",
  beam_dashboard: "/dashboard",
  beam_apps: "/apps",
  pricing: "/pricing",
  about: "/about",
  launchpad: "/launchpad",
  confirm: "/confirm",
  join: "/join",
  library_details: "/library/models/details",
  library_category: "/library/models/category",
  library_featured_models: "/library/models",
  library_all_models: "/library/models/all",
  login: "/login",
  github_redirect: "/github-redirect",
  github_redirect_org_access: "/github-redirect-org-access",
  sandbox: "/sandbox",
  sandbox_short: "/s",
  fast_sandbox: "/fast",
  playground: "/playground",
  models: "/models",
  integrations: "/integrations",
  settings: "/dashboard/settings",
  hackernews: "/hn",
  blog: "/blog",
  quickstart: "/quickstart",
  customers: "/customers"
};
