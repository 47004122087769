import React from "react";
import styled, { css } from "styled-components";
import NavLink from "components/NavLink";
import { sanityImgBuilder } from "screens/Homepage/Customers/sanity";

// FeaturedArticle interfaces
export interface FeaturedArticleType {
  title: string;
  description: string;
  link: string;
  imageUrl?: any; // Can be a Sanity image reference or a direct URL
  slug?: string; // Optional slug for direct article reference
}

interface FeaturedArticleProps {
  article: FeaturedArticleType;
  onClick?: () => void;
}

// FeaturedArticle component
export const FeaturedArticle: React.FC<FeaturedArticleProps> = ({ article, onClick }) => (
  <FeaturedArticleLink href={article.link} onClick={onClick}>
    <FeaturedArticleContainer>
      {article.imageUrl ? (
        <FeaturedArticleImageWrapper>
          <img
            src={sanityImgBuilder
              .image(article.imageUrl)
              .fit("crop")
              .crop("center")
              .quality(90)
              .auto("format")
              .url()}
            alt={article.title}
            style={{ width: "100%", height: "100%" }}
          />
        </FeaturedArticleImageWrapper>
      ) : (
        <FeaturedArticleImage />
      )}
      <FeaturedArticleTitle>{article.title}</FeaturedArticleTitle>
      <FeaturedArticleDescription>{article.description}</FeaturedArticleDescription>
    </FeaturedArticleContainer>
  </FeaturedArticleLink>
);

// Styled components for FeaturedArticle
const FeaturedArticleContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 240px;
  padding: 8px;
  gap: 4px;
`;

const articleImageBase = css`
  width: 100%;
  height: 141px;
  padding: 8px;
  border-radius: 8px;
  margin-bottom: 4px;
  background-color: ${props => props.theme.colors.gray6};
  border: 1px solid ${props => props.theme.colors.gray8};
`;

const FeaturedArticleImageWrapper = styled.div`
  ${articleImageBase};
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 4px;
  }
`;

const FeaturedArticleImage = styled.div`
  ${articleImageBase}
`;

const FeaturedArticleTitle = styled.div`
  ${({ theme }) => theme.styleSet.textSmPlus};
  color: ${props => props.theme.colors.gray12};
  margin: 0px;
`;

const FeaturedArticleDescription = styled.p`
  ${({ theme }) => theme.styleSet.textXs};
  color: ${props => props.theme.colors.gray11};
  margin: 0px;
`;

const FeaturedArticleLink = styled(NavLink)`
  ${({ theme }) => css`
    ${theme.styleSet.textSm};
    color: ${theme.colors.violet10};
    text-decoration: none;
    display: block;
  `}
`;

export default FeaturedArticle;
