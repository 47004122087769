import sanityClient from "@sanity/client";
import imageUrlBuilder from "@sanity/image-url";
import ConfigGlobalLoader from "services/config";

const configGlobal = ConfigGlobalLoader.config;

const client = sanityClient({
  projectId: configGlobal.sanityProjectID,
  dataset: "customers",
  apiVersion: "2021-10-21",
  useCdn: true
});

export default client;
export const sanityImgBuilder = imageUrlBuilder(client);
