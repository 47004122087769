import React, { ReactNode } from "react";
import { Icon } from "@beamcloud/design-system";
import styled from "styled-components";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

export interface ContextMenuItemProps {
  id: string;
  label: string;
  icon?: IconProp;
  onClick?: () => void;
}

export interface ContextMenuProps {
  items?: ContextMenuItemProps[];
  style?: React.CSSProperties;
  children?: ReactNode;
}

const ContextMenu: React.FC<ContextMenuProps> = ({ items = [], style, children }) => {
  return (
    <MenuContainer style={style}>
      {children ||
        items.map(item => (
          <MenuItem key={item.id} onClick={item.onClick}>
            {item.icon && <Icon icon={item.icon} size="sm" />}
            {item.label}
          </MenuItem>
        ))}
    </MenuContainer>
  );
};

const MenuContainer = styled.div`
  min-width: 180px;
  width: max-content;
  padding: 4px;
  background-color: ${props => props.theme.colors.gray2};
  border: 1px solid ${props => props.theme.colors.gray7};
  border-radius: 10px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
  z-index: 999;
  overflow: hidden;
`;

const MenuItem = styled.button`
  all: unset;
  box-sizing: border-box;
  border-radius: 6px;
  display: flex;
  gap: 12px;
  align-items: center;
  padding: 8px 10px;
  width: 100%;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  cursor: pointer;
  white-space: nowrap;
  color: ${props => props.theme.colors.gray12};
  &:hover {
    background-color: ${props => props.theme.colors.gray8};
  }
`;

export default ContextMenu;
