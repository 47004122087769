import sanityClient from "screens/Homepage/Customers/sanity";
import { FeaturedArticleType } from "./FeaturedArticle";

/**
 * Configuration for featured article fetching
 */
interface FeaturedConfig {
  preferredSlug?: string; // If provided, will try to fetch this specific article
}

// Default configuration
const defaultConfig: FeaturedConfig = {
  preferredSlug: "geospy-case-study" // Default featured customer
};

/**
 * Fetches a featured customer from Sanity based on configuration
 * @param config Optional configuration to customize the fetch behavior
 * @returns Promise<FeaturedArticleType | null>
 */
export const fetchFeatured = async (
  config?: Partial<FeaturedConfig>
): Promise<FeaturedArticleType | null> => {
  // Merge provided config with defaults
  const { preferredSlug } = {
    ...defaultConfig,
    ...config
  };

  try {
    let customer = null;

    // If a preferred slug is provided, fetch that specific customer
    if (preferredSlug) {
      customer = await sanityClient.fetch(`
        *[_type == "caseStudy" && slug.current == "${preferredSlug}"][0] {
          title,
          "slug": slug.current,
          coverImage,
          company->{
            name
          }
        }
      `);
    }

    // If no customer found by slug or no slug provided
    if (!customer) {
      customer = await sanityClient.fetch(`
        *[_type == "caseStudy"] | order(_createdAt desc)[0] {
          title,
          "slug": slug.current,
          coverImage,
          company->{
            name
          }
        }
      `);
    }

    if (!customer) return null;

    // Transform to FeaturedArticleType format
    return {
      title: customer.company.name,
      description: customer.title,
      link: `/customers/${customer.slug}`,
      imageUrl: customer.coverImage,
      slug: customer.slug
    };
  } catch (error) {
    // In case of error, return null
    console.error("Error fetching featured customer:", error);
    return null;
  }
};
