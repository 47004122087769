import { ROUTES } from "routes/constants";
import {
  docsLink,
  slackLink,
  statusLink,
  privacyLink,
  termsLink,
  jobsLink,
  githubLink,
  linkedinLink,
  xLink,
  changelogLink
} from "constants/Links";

export const FooterLinks = [
  {
    sectionName: "Company",
    sectionLinks: [
      {
        name: "About",
        link: `${ROUTES.about}`
      },
      {
        name: "Jobs",
        external: true,
        link: jobsLink
      },
      {
        name: "Github",
        external: true,
        link: githubLink
      },
      {
        name: "LinkedIn",
        external: true,
        link: linkedinLink
      },
      {
        name: "Twitter",
        external: true,
        link: xLink
      }
    ]
  },
  {
    sectionName: "Resources",
    sectionLinks: [
      {
        name: "Docs",
        external: true,
        link: docsLink
      },
      {
        name: "Blog",
        link: `${ROUTES.blog}`
      },
      {
        name: "Status",
        external: true,
        link: statusLink
      },
      {
        name: "Changelog",
        external: true,
        link: changelogLink
      },
      {
        name: "Support",
        external: true,
        link: slackLink
      },
      {
        name: "Customers",
        link: `${ROUTES.customers}`
      }
    ]
  },
  {
    sectionName: "Legal",
    sectionLinks: [
      {
        name: "Privacy Policy",
        external: true,
        link: privacyLink
      },
      {
        name: "Terms of Service",
        external: true,
        link: termsLink
      }
    ]
  }
];
