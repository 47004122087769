import React, { useState, useRef, useEffect } from "react";
import styled, { css } from "styled-components";
import NavLink from "components/NavLink";
import { Icon } from "@beamcloud/design-system";
import { NavItemType, MenuItemType } from "./NavItems";
import { fetchFeatured } from "./featured";
import FeaturedArticle, { FeaturedArticleType } from "./FeaturedArticle";
import { isMobile } from "react-device-detect";

interface NavItemProps {
  item: NavItemType;
}

const usePositionUpdates = (menuRef: React.RefObject<HTMLDivElement>, isOpen: boolean, isMobile: boolean) => {
  useEffect(() => {
    const updatePositions = () => {
      if (!isMobile) {
        const navItemList = document.querySelector("nav ul");
        if (navItemList) {
          document.documentElement.style.setProperty(
            "--navItemList-left",
            `${navItemList.getBoundingClientRect().left}px`
          );
        }
        if (menuRef.current) {
          document.documentElement.style.setProperty(
            "--menu-container-left",
            `${menuRef.current.getBoundingClientRect().left}px`
          );
        }
      }
    };
    updatePositions();
    window.addEventListener("resize", updatePositions);
    window.addEventListener("scroll", updatePositions);
    return () => {
      window.removeEventListener("resize", updatePositions);
      window.removeEventListener("scroll", updatePositions);
    };
  }, [menuRef, isMobile, isOpen]);
};

const NavItemComponent: React.FC<NavItemProps> = ({ item }) => {
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);
  const navButtonRef = useRef<HTMLButtonElement>(null);
  const [featuredArticle, setFeaturedArticle] = useState<FeaturedArticleType | null>(null);
  const [isHovering, setIsHovering] = useState(false);

  // Fetch featured article helper
  const fetchFeaturedArticle = () => {
    fetchFeatured({ preferredSlug: item.featuredArticle?.slug })
      .then(article => article && setFeaturedArticle(article))
      .catch(error => console.error("Error fetching featured article:", error));
  };

  // Update positions
  usePositionUpdates(menuRef, isOpen, isMobile);

  // Handle menu interactions
  const closeMenu = () => {
    setIsOpen(false);
    setIsHovering(false);
  };

  const toggleMenu = (e?: React.MouseEvent) => {
    e?.stopPropagation(); // Only stop propagation if event exists
    const newIsOpen = !isOpen;
    setIsOpen(newIsOpen);
    setIsHovering(newIsOpen);

    if (newIsOpen) {
      fetchFeaturedArticle();
    }
  };

  // Desktop hover effect
  useEffect(() => {
    if (!isMobile && isHovering !== isOpen) {
      if (isHovering) {
        setIsOpen(true);
        fetchFeaturedArticle();
      } else {
        const timer = setTimeout(() => {
          if (!isHovering) setIsOpen(false);
        }, 100);
        return () => clearTimeout(timer);
      }
    }
  }, [isHovering, isOpen, item.featuredArticle?.slug]);

  // Handle mouse enter/leave for desktop
  const handleMouseEvents = {
    onMouseEnter: () => !isMobile && setIsHovering(true),
    onMouseLeave: () => !isMobile && setIsHovering(false)
  };

  if (item.isMenu) {
    return (
      <MenuContainer
        ref={menuRef}
        {...handleMouseEvents}
        onBlur={closeMenu}
        tabIndex={0} // Make the div focusable
      >
        <NavButton ref={navButtonRef} $isOpen={isOpen} onClick={toggleMenu}>
          <NavItemText>{item.name}</NavItemText>
          <ChevronIcon icon="fas-angle-down" size={12} $rotated={isOpen} />
        </NavButton>
        <MenuContent isOpen={isOpen}>
          <MenuSection>
            <MenuSectionTitle>{item.name}</MenuSectionTitle>
            <MenuItemsList>
              {item.menuItems?.map((subItem: MenuItemType, subIndex: number) => (
                <MenuItem key={`subitem-${subIndex}`}>
                  <IconBox>
                    <Icon icon={subItem.icon} size={16} color="gray11" />
                  </IconBox>
                  <MenuItemLink href={subItem.link} onClick={closeMenu}>
                    <MenuItemName>{subItem.name}</MenuItemName>
                    {subItem.description && <MenuItemDescription>{subItem.description}</MenuItemDescription>}
                  </MenuItemLink>
                </MenuItem>
              ))}
            </MenuItemsList>
          </MenuSection>
          <MenuSection $borderLeft={true}>
            <MenuSectionTitle>Featured</MenuSectionTitle>
            {featuredArticle && <FeaturedArticle article={featuredArticle} onClick={closeMenu} />}
          </MenuSection>
        </MenuContent>
      </MenuContainer>
    );
  }
  return item.link ? (
    <NavItem href={item.link} onClick={closeMenu}>
      {item.name}
    </NavItem>
  ) : null;
};

export default NavItemComponent;

// Styled components - condensed where possible
const MenuContainer = styled.div`
  position: relative;
  display: inline-block;
  @media (max-width: 768px) {
    display: block;
    width: 100%;
    overflow: hidden;
  }
`;

const NavItemText = styled.span`
  ${({ theme }) => css`
    text-decoration: none;
    ${theme.styleSet.textSm};
    color: ${theme.colors.gray11};
    cursor: pointer;
  `}
`;

const MenuSectionTitle = styled.span`
  ${({ theme }) => css`
    ${theme.styleSet.textSmPlus};
    color: ${theme.colors.gray11};
    padding: 4px 8px 8px 8px;
    width: fit-content;
    @media (max-width: 768px) {
      display: none;
    }
  `}
`;

const NavButton = styled.button<{ $isOpen?: boolean }>`
  display: flex;
  align-items: center;
  gap: 6px;
  background: none;
  border: 1px solid transparent;
  padding: 8px 12px;
  border-radius: 8px;
  cursor: pointer;
  color: ${props => props.theme.colors.gray11};

  &:hover {
    background-color: ${props => props.theme.colors.gray4};
    border-color: ${props => props.theme.colors.gray8};
    ${NavItemText} {
      color: ${props => props.theme.colors.gray12};
    }
  }

  /* Apply the same styles when $isOpen is true */
  ${props =>
    props.$isOpen &&
    css`
      background-color: ${props.theme.colors.gray4};
      border-color: ${props.theme.colors.gray8};
      ${NavItemText} {
        color: ${props.theme.colors.gray12};
      }
    `}

  @media (max-width: 768px) {
    width: fit-content;
    justify-content: space-between;
    border: none;
    background: none;
    padding: 0px 12px;
    &:hover {
      background: none;
      border: none;
    }

    /* Apply mobile styles when $isOpen is true */
    ${props =>
      props.$isOpen &&
      css`
        background: none;
        border: none;
      `}
  }
`;

const MenuContent = styled.div<{ isOpen: boolean }>`
  display: flex;
  flex-direction: row;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 20;
  margin-top: 4px; /* 4px below the NavButton */
  background-color: ${props => props.theme.colors.gray3};
  border: 1px solid ${props => props.theme.colors.gray8};
  border-radius: 8px;
  box-shadow: 0px 1px 12px rgba(0, 0, 0, 0.1);
  min-width: max(220px, var(--nav-button-width, 0));
  opacity: ${props => (props.isOpen ? 1 : 0)};
  transform: scale(${props => (props.isOpen ? 1 : 0.95)});
  transform-origin: top;
  transition: opacity 0.2s ease-in-out, transform 0.2s cubic-bezier(0.25, 0.1, 0.25, 1),
    visibility 0s ${props => (props.isOpen ? "0s" : "0.2s")};
  visibility: ${props => (props.isOpen ? "visible" : "hidden")};
  pointer-events: ${props => (props.isOpen ? "auto" : "none")};

  @media (min-width: 769px) {
    /* Desktop positioning - align with NavItemList */
    position: absolute; /* Changed back to absolute for proper positioning relative to NavButton */
    left: calc(
      var(--navItemList-left, 0) - var(--menu-container-left, 0)
    ); /* Offset to align with NavItemList */
    transform: scale(${props => (props.isOpen ? 1 : 0.95)});
    transform-origin: top;
    /* Maintain width and appearance */
    width: auto;
    min-width: 600px; /* Ensure enough space for both menu sections */
    z-index: 30; /* Ensure it appears above other elements */
  }

  @media (max-width: 768px) {
    position: static;
    flex-direction: column;
    margin-top: ${props => (props.isOpen ? "4px" : "0")};
    margin-left: 4px;
    width: 100%;
    transform: none;
    max-height: ${props => (props.isOpen ? "1000px" : "0")};
    overflow: hidden;
    transition: all 0.3s ease-in-out;
    border: none;
    background: none;
    box-shadow: none;
    opacity: 1;
    visibility: visible;
  }
`;

const MenuItem = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  padding: 8px;
  border-radius: 8px;
  width: 100%;
  &:hover {
    background-color: ${props => props.theme.colors.gray7};
  }
  @media (max-width: 768px) {
    &:hover {
      background-color: transparent;
    }
  }
`;

const MenuSection = styled.div<{ $borderLeft?: boolean }>`
  display: flex;
  flex-direction: column;
  padding: 8px;
  ${({ $borderLeft, theme }) =>
    $borderLeft &&
    css`
      border-left: 1px solid ${theme.colors.gray8};
      @media (max-width: 768px) {
        display: none;
      }
    `}
  @media (max-width: 768px) {
    padding: 0;
  }
`;

const MenuItemsList = styled.div`
  min-width: 360px;
  display: flex;
  flex-direction: column;
  @media (max-width: 768px) {
    min-width: 100%;
    width: 100%;
    background: none;
    border: none;
  }
`;

const ChevronIcon = styled(Icon)<{ $rotated?: boolean }>`
  && {
    color: inherit;
    transition: transform 0.3s cubic-bezier(0.34, 1.56, 0.64, 1);
    transform: ${props => (props.$rotated ? "rotate(-180deg)" : "rotate(0)")};
  }
`;

const MenuItemLink = styled(NavLink)`
  && {
    display: block;
    width: 100%;
    padding: 0px 12px;
    text-decoration: none;
  }
`;

const MenuItemName = styled.div`
  ${({ theme }) => css`
    ${theme.styleSet.textSmPlus};
    font-weight: 500;
    color: ${theme.colors.gray12};
    margin-bottom: 4px;
  `}
`;

const MenuItemDescription = styled.div`
  ${({ theme }) => css`
    ${theme.styleSet.textXs};
    color: ${theme.colors.gray11};
  `}
`;

const NavItem = styled(NavLink)`
  && {
    ${({ theme }) => css`
      text-decoration: none;
      border: 1px solid transparent;
      padding: 8px 12px;
      cursor: pointer;
      ${theme.styleSet.textSm};
      color: ${theme.colors.gray11};
      &:hover,
      &:active {
        color: ${theme.colors.gray12};
      }
    `}
  }
`;

const IconBox = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 28px;
    height: 28px;
    background-color: ${theme.colors.gray9};
    border-radius: 8px;
  `}
`;
