import { css } from "styled-components";

const INTER_VAR = '"Inter var", system-ui';
const SEQUEL_SANS = '"Sequel Sans", "Inter var", system-ui';

const TitleCommon = css`
  font-family: ${SEQUEL_SANS};
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`;

const TextCommon = css`
  font-family: ${INTER_VAR};
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`;

export const typography = {
  title80: css`
    ${TitleCommon};
    font-weight: 410;
    font-size: 80px;
    line-height: 110%;
  `,

  title72: css`
    ${TitleCommon};
    font-weight: 410;
    font-size: 72px;
    line-height: 110%;
  `,

  title64: css`
    ${TitleCommon};
    font-weight: 410;
    font-size: 64px;
    line-height: 110%;
  `,

  title60: css`
    ${TitleCommon};
    font-weight: 410;
    font-size: 60px;
    line-height: 110%;
  `,

  title56: css`
    ${TitleCommon};
    font-weight: 410;
    font-size: 56px;
    line-height: 110%;
  `,

  title52: css`
    ${TitleCommon};
    font-weight: 410;
    font-size: 52px;
    line-height: 115%;
  `,

  title48: css`
    ${TitleCommon};
    font-weight: 410;
    font-size: 48px;
    line-height: 115%;
  `,

  title44: css`
    ${TitleCommon};
    font-weight: 410;
    font-size: 44px;
    line-height: 120%;
  `,

  title40: css`
    ${TitleCommon};
    font-weight: 410;
    font-size: 40px;
    line-height: 120%;
  `,

  title36: css`
    ${TitleCommon};
    font-weight: 360;
    font-size: 36px;
    line-height: 120%;
  `,

  title32: css`
    ${TitleCommon};
    font-weight: 360;
    font-size: 32px;
    line-height: 120%;
  `,

  title28: css`
    ${TitleCommon};
    font-weight: 360;
    font-size: 28px;
    line-height: 120%;
  `,

  title24: css`
    ${TitleCommon};
    font-weight: 360;
    font-size: 24px;
    line-height: 132%;
  `,

  title22: css`
    ${TitleCommon};
    font-weight: 360;
    font-size: 22px;
    line-height: 140%;
  `,

  title20: css`
    ${TitleCommon};
    font-weight: 360;
    font-size: 20px;
    line-height: 132%;
    letter-spacing: 0%;
  `,

  title18: css`
    ${TitleCommon};
    font-weight: 360;
    font-size: 18px;
    line-height: 132%;
  `,

  title16: css`
    ${TitleCommon};
    font-weight: 360;
    font-size: 16px;
    line-height: 132%;
  `,

  textXlLight: css`
    ${TextCommon};
    font-weight: 350;
    font-size: 20px;
    line-height: 28px;
  `,

  textLgLight: css`
    ${TextCommon};
    font-weight: 350;
    font-size: 18px;
    line-height: 26px;
  `,

  textBaseLight: css`
    ${TextCommon};
    font-weight: 350;
    font-size: 16px;
    line-height: 24px;
  `,

  textSmLight: css`
    ${TextCommon};
    font-weight: 350;
    font-size: 14px;
    line-height: 20px;
  `
};
