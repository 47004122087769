import React, { forwardRef } from "react";
import { Link as BaseLink, NavLinkProps } from "react-router-dom";
import styled from "styled-components";

interface INavLinkProps extends Omit<NavLinkProps, "to"> {
  href: string;
  reload?: boolean;
  className?: string;
  newWindow?: boolean;
  onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
}

const NavLink = forwardRef<HTMLAnchorElement, INavLinkProps>(
  ({ href, reload, className, children, newWindow = false, onClick }, ref) => {
    const extraProps = {
      ...(newWindow && { target: "_blank" }),
      onClick
    };

    if (reload || href.startsWith("http")) {
      return (
        <StyledOutLink ref={ref} href={href} className={className} {...extraProps}>
          {children}
        </StyledOutLink>
      );
    }

    return (
      <BaseLink ref={ref} to={href} className={className} {...extraProps}>
        {children}
      </BaseLink>
    );
  }
);

export default NavLink;

const StyledOutLink = styled.a`
  color: inherit;
`;
