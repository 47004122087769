import React, { useEffect, useState, useCallback } from "react";
import { Link, useHistory, useRouteMatch } from "react-router-dom";
import styled, { css } from "styled-components";
import DesktopLogo from "assets/beam/beam-logo.svg";
import { Button, Icon, defaultTheme as theme } from "@beamcloud/design-system";
import { ROUTES } from "routes/constants";
import useWindowSize from "utils/useWindowSize";
import navItems, { NavItemType } from "./NavItems";
import ActionComponents from "./ActionComponents";
import NavItem from "./NavItem";
import ContextMenu from "components/ContextMenu";
import { faExternalLink, faCopy } from "@fortawesome/pro-duotone-svg-icons";

interface HeaderProps {}

// Position interface for the context menu
interface Position {
  x: number;
  y: number;
}

const Header: React.FC<HeaderProps> = () => {
  const match = useRouteMatch();
  const [isMobile, setIsMobile] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { width } = useWindowSize();
  const history = useHistory();
  const [showContextMenu, setShowContextMenu] = useState(false);
  const [contextMenuPosition, setContextMenuPosition] = useState<Position>({ x: 0, y: 0 });

  const handleSearch = (value: string) => {};

  const onSignIn = () => {
    // localStorage.setItem("authRedirectURI", JSON.stringify(`${ROUTES.beam_dashboard}/apps`));
    history.push(`${ROUTES.login}`);
  };

  const onGetStarted = () => {
    // localStorage.setItem("authRedirectURI", JSON.stringify(`${ROUTES.beam_dashboard}/apps`));
    history.push(`${ROUTES.login}`);
  };

  const openInNewTab = () => {
    window.open(window.location.href, "_blank");
    setShowContextMenu(false);
  };

  const copyLogoAsSVG = async () => {
    try {
      const response = await fetch("/BeamLogo.svg");
      const svgContent = await response.text();
      await navigator.clipboard.writeText(svgContent);
      console.log("Logo copied as SVG");
    } catch (error) {
      console.error("Failed to copy logo as SVG:", error);
    }
    setShowContextMenu(false);
  };

  const contextMenuItems = [
    { id: "openTab", label: "Open link in new tab", icon: faExternalLink, onClick: openInNewTab },
    { id: "copySvg", label: "Copy logo as SVG", icon: faCopy, onClick: copyLogoAsSVG }
  ];

  const handleLogoRightClick = useCallback((e: React.MouseEvent) => {
    e.preventDefault();
    setContextMenuPosition({ x: e.clientX, y: e.clientY });
    setShowContextMenu(true);
  }, []);

  const handleDocumentClick = useCallback(() => {
    if (showContextMenu) {
      setShowContextMenu(false);
    }
  }, [showContextMenu]);

  useEffect(() => {
    if (width! <= (theme.breakpoints.md ?? 768)) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [width]);

  useEffect(() => {
    history.listen(() => setIsMenuOpen(false));
  }, [history]);

  useEffect(() => {
    if (showContextMenu) {
      document.addEventListener("click", handleDocumentClick);
    }
    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, [showContextMenu, handleDocumentClick]);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <>
      <Container>
        <NavigationWrapper $isMobile={isMobile}>
          <NavigationHeader>
            <IconLink to={`${match.url}`} onContextMenu={handleLogoRightClick}>
              <Logo src={DesktopLogo} alt="beam-logo" />
            </IconLink>
          </NavigationHeader>
          {isMobile && (
            <Button buttonTheme="secondary" variant="outline" onClick={toggleMenu}>
              <Icon size={18} icon={isMenuOpen ? "far-multiply" : "far-bars"} color="white" />
            </Button>
          )}
        </NavigationWrapper>
        <NavBar $isMobile={isMobile} $open={isMenuOpen}>
          <NavItemList>
            {navItems.map((item: NavItemType, index: number) => (
              <ListItem key={`item-${index}`}>
                <NavItem item={item} />
              </ListItem>
            ))}
          </NavItemList>
          <ActionComponents
            handleSearch={handleSearch}
            handleSignIn={onSignIn}
            handleGetStarted={onGetStarted}
            isMobile={isMobile}
          />
        </NavBar>
        {showContextMenu && (
          <ContextMenuWrapper style={{ top: contextMenuPosition.y, left: contextMenuPosition.x }}>
            <ContextMenu items={contextMenuItems} style={{ position: "absolute" }} />
          </ContextMenuWrapper>
        )}
      </Container>
      {isMobile && isMenuOpen && <BlurredBackground />}
    </>
  );
};

export default Header;

const Container = styled.header`
  position: sticky;
  top: 0; // This ensures the header sticks to the top of the viewport
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 48px;
  border-bottom: 1px solid ${props => props.theme.colors.gray7};
  background-color: ${props => props.theme.colors.gray1};
  width: 100vw;
  height: 60px;
  z-index: 15; // Ensure this is high enough to stay above other content

  ${({ theme }) => css`
    padding: 0 64px;

    @media (max-width: ${theme.breakpoints.xl}px) {
      padding: 0px 32px;
    }

    @media (max-width: ${theme.breakpoints.lg}px) {
      padding: 0px 24px;
    }

    @media (max-width: ${theme.breakpoints.md}px) {
      padding: 0px 16px;
    }
  `}
`;

const NavigationWrapper = styled.div<{ $isMobile: boolean }>`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: ${({ $isMobile }) => ($isMobile ? "100%" : "max-content")};
  background-color: inherit;
`;

const NavigationHeader = styled.h1`
  margin: 0;
`;

const IconLink = styled(Link)`
  && {
    width: fit-content;
    height: fit-content;
  }
`;

const Logo = styled.img`
  height: 25px;
  width: auto;
`;

const NavItemList = styled.ul`
  all: unset;
`;

const ListItem = styled.li`
  all: unset;

  @media (max-width: 768px) {
    border-bottom: 1px solid ${props => props.theme.colors.gray6};
    width: 100%;
  }
`;

const NavBar = styled.nav<{ $isMobile: boolean; $open: boolean }>`
  background-color: inherit;
  ${({ $isMobile, $open }) => {
    if ($isMobile) {
      return css`
        position: absolute;
        display: flex;
        flex-direction: column;
        gap: 24px;
        top: 100%;
        left: 0;
        right: 0;
        padding: 0 16px;
        padding-bottom: 20px;
        border-top: 1px solid ${props => props.theme.colors.gray7};
        overflow-y: auto;
        z-index: -2;
        opacity: 1;
        transition: transform ease-in-out 100ms, opacity ease-in-out 100ms, visibility ease-in-out 100ms;
        will-change: transform, opacity, visibility;
        ${!$open &&
          css`
            transform: translateY(-100%);
            opacity: 0;
            visibility: hidden;
          `}

        ${NavItemList} {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
        }

        ${ListItem} {
          padding: 12px 0;
        }
      `;
    }

    return css`
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      ${NavItemList} {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 0px;
      }
    `;
  }}
`;

const BlurredBackground = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 11;
  top: 60px;
  left: 0;
  bottom: 0;
  backdrop-filter: blur(16px);
`;

const ContextMenuWrapper = styled.div`
  position: fixed;
  z-index: 999;
`;
